<template>
	<div class="h-screen bg-indigo-900">
		<div class="text-white max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
			<h2 class="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
				Completing Sign Up Process
			</h2>
			<div v-if="noMail" class="mt-8 flex justify-center">
				<div class="rounded-md shadow-sm">
					<div>
						<input
							v-model="email"
							aria-label="Email address"
							name="email"
							type="email"
							required
							class="appearance-none rounded-none relative  w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
							placeholder="Confirm Email address"
						/>
					</div>
				</div>
				<div class="ml-3 inline-flex">
					<button
						class="inline-flex items-center justify-center px-5  border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out"
						@click="magic"
					>
						OK
					</button>
				</div>
			</div>
			<div v-else>
				Hold tight, if you can't get passed this page please contact
				<a class="text-red-600" href="mailto://support@storism.io">support@storism.io</a>
			</div>
		</div>
	</div>
</template>

<script>
import firebase from 'firebase/app'
export default {
	name: 'FinishSignUp',
	data() {
		return {
			email: window.localStorage.getItem('emailForSignIn'),
			noMail: false
		}
	},
	metaInfo() {
		return {
			title: 'Finish Sign Up'
		}
	},
	mounted() {
		// Confirm the link is a sign-in with email link.
		if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
			this.$analytics.logEvent('sign_up', { method: 'magic_link' })
			// Additional state parameters can also be passed via URL.
			// This can be used to continue the user's intended action before triggering
			// the sign-in operation.
			// Get the email if available. This should be available if the user completes
			// the flow on the same device where they started it.
			var email = window.localStorage.getItem('emailForSignIn')
			if (!email) {
				this.noMail = true
			} else {
				this.magic()
			}
			// The client SDK will parse the code from the link for you.
		}
	},
	methods: {
		magic() {
			firebase
				.auth()
				.signInWithEmailLink(this.email, window.location.href)
				.then(() => {
					// Clear email from storage.
					window.localStorage.removeItem('emailForSignIn')

					this.$router.push({ name: 'account' })
					// You can access the new user via result.user
					// Additional user info profile not available via:
					// result.additionalUserInfo.profile == null
					// You can check if the user is new or existing:
					// result.additionalUserInfo.isNewUser
				})
			// .catch(function(error) {
			// 	// Some error occurred, you can inspect the code: error.code
			// 	// Common errors could be invalid email and invalid or expired OTPs.
			// })
		}
	}
}
</script>

<style></style>
